const link = "https://intranet.rcmoviles.com/.php/";

const API =  link + "zUserController.php"
const API2 = link + "zLogIn.php"
const API3 = link + "zVentasController.php"
const API4 = link + "zClientesController.php"
const API5 = link + "zventasGETDate.php"
const API6 = link + "zInventarios.php"
const API7 = link + "zProductos.php"
const API8 = link + "zProductos.php"
const API9 = link + "zAlmacen.php"
const API10 = link + "zActualizarInventarios.php"
const API11 = link + "MegaConsulta.php"

export { API, API2, API3, API4, API5, API6, API7, API8, API9, API10, API11 };